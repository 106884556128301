import { graphConfig } from 'config/authConfig';
import HttpClient from '../httpClient/httpClient';
import {
  ICreateUserDto,
  IInviteUserDto,
  IUserDetails,
  IUserDetailsWithTags,
  UserCreationResponse,
  UserIdAndEmail,
} from '../../interfaces/user.interface';
import { commonConstants } from 'constants/common';
import { UseQueryResult, useQuery } from 'react-query';
import { User } from '@microsoft/microsoft-graph-types';

class UserService {
  private user: User | undefined = undefined;

  private azureAdScope = 'User.Read';

  private httpClient;

  private azureAdScopeForUsers = commonConstants.AZURE_AD_SCOPE;

  private httpClientForUSers;

  constructor() {
    this.httpClient = new HttpClient(this.azureAdScope).client;
    this.httpClientForUSers = new HttpClient(this.azureAdScopeForUsers).client;
  }

  public getUserFromApi() {
    return this.httpClient.get(graphConfig.graphMeEndpoint + '/v1.0/me');
  }

  getUser(): Promise<User> {
    return new Promise((resolve) => {
      if (this.user) {
        resolve(this.user);
      } else {
        this.getUserFromApi().then(({ data }) => {
          this.user = data;
          resolve(data);
        });
      }
    });
  }

  /**
   * API call to create a new user. Currently only supports provider user type.
   * @param userDetails: ICreateUserDto
   */
  async createUser(
    userDetails: ICreateUserDto
  ): Promise<{ aadUser: UserCreationResponse; userDetails: IUserDetails }> {
    const response = await this.httpClientForUSers.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/admin/create`,
      userDetails
    );
    return response.data;
  }

  /**
   * API call to invite an existing Microsoft user to our tenant. Supports all user types.
   * @param userDetails: IInviteUserDto
   */
  async inviteUser(userDetails: IInviteUserDto): Promise<IUserDetails> {
    const response = await this.httpClientForUSers.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/admin/invite`,
      userDetails
    );
    return response.data;
  }

  async getAllUserNames() {
    return this.httpClientForUSers
      .get(`${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/all/names`)
      .then((response: { data: UserIdAndEmail[] }) => {
        const result = response.data;
        return result;
      });
  }

  useAllUserNamesWithTags(): UseQueryResult<IUserDetailsWithTags[]> {
    return useQuery(['/user-details/all/tags'], async ({ signal }) => {
      const result = await this.httpClientForUSers.get(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/all/tags`,
        {
          signal,
        }
      );
      return result.data;
    });
  }

  async getUsersWithTag(tagId: number) {
    return this.httpClientForUSers
      .get(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/tag/${tagId}`
      )
      .then((response: { data: IUserDetails[] }) => {
        const result = response.data;
        return result;
      });
  }

  async linkUsersToTag(tagId: number, userIds: number[]) {
    return this.httpClientForUSers
      .post(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/update-users-tags/${tagId}`,
        { userIds: userIds }
      )
      .then((response: { data: boolean }) => {
        return response.data;
      });
  }

  async linkTagsToUser(UserId: number, tagIds: number[]) {
    return this.httpClientForUSers
      .post(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/link-tags/${UserId}`,
        { tagIds: tagIds }
      )
      .then((response: { data: boolean }) => {
        return response.data;
      });
  }

  async uploadAvatar(file: Blob, userId: number) {
    const fd = new FormData();
    fd.append('file', file);
    const path = await this.httpClientForUSers.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/admin/avatar/${userId}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return path.data.avatar;
  }

  async getUserByUserId(userId: number) {
    return this.httpClientForUSers
      .get(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/userByUserId/${userId}`
      )
      .then((response: { data: IUserDetails }) => {
        const result = response.data;
        return result;
      });
  }

  async getUserDetails() {
    return this.httpClientForUSers
      .get(`${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details`)
      .then((response: { data: IUserDetails }) => {
        const result = response.data;
        return result;
      });
  }

  async getUsersByLocationId(
    locationId: number
  ): Promise<IUserDetailsWithTags[]> {
    const result = await this.httpClientForUSers.get(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/all/users/${locationId}`
    );
    return result.data;
  }

  async updateUserDetails(
    userData: Partial<IUserDetailsWithTags>,
    userId: number
  ): Promise<void> {
    await this.httpClientForUSers.patch(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/admin/update/${userId}`,
      userData
    );
  }

  async addUserDetails(userData: Partial<IUserDetails>) {
    const response = await this.httpClientForUSers.post(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/admin/add`,
      userData
    );
    return response;
  }

  async deleteUser(userId: number) {
    return this.httpClientForUSers
      .delete(
        `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/delete-user/${userId}`
      )
      .then((response: { data: boolean }) => {
        const result = response.data;
        return result;
      });
  }

  getAADUsers(searchText: string, enabled: boolean): UseQueryResult<User[]> {
    return useQuery(
      ['/user-details/aad-users'],
      async ({ signal }) => {
        const result = await this.httpClientForUSers.get(
          `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/aad-users?search=${searchText}`,
          {
            signal,
          }
        );
        return result.data;
      },
      { enabled }
    );
  }

  getDBUsers(
    searchText: string,
    enabled: boolean
  ): UseQueryResult<IUserDetailsWithTags[]> {
    return useQuery(
      ['/user-details/db-users'],
      async ({ signal }) => {
        const result = await this.httpClientForUSers.get(
          `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/db-users?search=${searchText}`,
          {
            signal,
          }
        );
        return result.data;
      },
      { enabled }
    );
  }

  async getUserWithLocationsByAadId(
    aadObjectId: string
  ): Promise<IUserDetailsWithTags> {
    const response = await this.httpClientForUSers.get(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/aad-id/${aadObjectId}`
    );
    return response.data;
  }

  async checkUserExists(email: string) {
    const response = await this.httpClientForUSers.get(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/user-exists/${email}`
    );
    return response;
  }

  async checkH2DigitalUserExists(email: string, signal: AbortSignal) {
    const result = await this.httpClient.get(
      `${process.env.REACT_APP_H2_DIGITAL_API_URL}/user-details/user-exists/${email}`,
      { signal }
    );
    return result;
  }
}

const SUserService = new UserService();
export default SUserService;
