import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import './Users.scss';
import SUserService from '../../services/user/user.service';
import {
  IUpdateUserDetailsWithTags,
  IUserDetailsWithTags,
} from '../../interfaces/user.interface';
import LocationAutocomplete from '../../components/LocationAutocomplete/LocationAutocomplete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FactoryIcon from '@mui/icons-material/Factory';
import { debounce, omit } from 'lodash';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { emailExpression } from 'constants/common';
import { ListUsers } from './components/ListUsers';
import { EditUserDetails } from './components/EditUserDetails';
import { ILocationMiniInfo } from 'interfaces/location.interface';
import SLocationService from '../../services/location/location.service';
import NewUserDialog from './components/NewUserDialog';
import { User } from '@microsoft/microsoft-graph-types';

enum UserTab {
  BY_PROJECT = 0,
  FROM_AAD = 1,
  FROM_DB = 2,
}

const Users: React.FunctionComponent = () => {
  const [selectedLocation, setSelectedLocation] =
    useState<ILocationMiniInfo | null>(null);
  const [locationList, setLocationList] = useState<ILocationMiniInfo[]>([]);
  const [userLocation, setUserLocation] = useState<ILocationMiniInfo | null>();
  const [AADUsers, setAADUsers] = useState<User[]>([]);
  const [usersList, setUsersList] = useState<IUpdateUserDetailsWithTags[]>([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [newUserDialogue, setNewUserDialogue] = useState(false);
  const [editUserDialogue, setEditUserDialogue] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [selectedUser, setSelectedUser] =
    useState<IUpdateUserDetailsWithTags>();
  const [isUserAADOnly, setIsUserAADOnly] = useState(false);
  const [selectedAADUser, setSelectedAADUser] = useState<User>();
  const [searchQuery, setSearchQuery] = useState('');
  const [tabValue, setTabValue] = useState<UserTab>(UserTab.BY_PROJECT);
  const { showSnackbar } = useSnackbar();

  const {
    data: aadUsersData,
    error: aadUsersError,
    refetch: refetchAadUsers,
    isFetching: isAadUsersFetching,
  } = SUserService.getAADUsers(searchQuery, tabValue === UserTab.FROM_AAD);
  const {
    data: dbUsersData,
    error: dbUsersError,
    refetch: refetchDbUsers,
    isFetching: isDbUsersFetching,
  } = SUserService.getDBUsers(searchQuery, tabValue === UserTab.FROM_DB);

  const fetchUsersOfLocation = (locationId: number) => {
    SUserService.getUsersByLocationId(locationId)
      .then((response) => {
        setUsersList(response);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setUsersLoading(false);
      });
  };

  useEffect(() => {
    SLocationService.findNames()
      .then((response) => {
        setLocationList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleLocationSelect = (
    e: SyntheticEvent<Element, Event>,
    location: ILocationMiniInfo | null
  ) => {
    setUsersLoading(true);
    setUserLocation(location);
    setSelectedLocation(location);
    if (location?.id) {
      fetchUsersOfLocation(location?.id);
    }
  };

  const handleCloseEdit = () => {
    setEditUserDialogue(false);
  };

  const handleUpdateUserDetails = () => {
    if (selectedUser?.id && selectedUser.secondaryEmail) {
      const validEmail = emailExpression.test(selectedUser.secondaryEmail);
      if (validEmail) {
        const tagIds = selectedUser.tags.map((tag) => tag.id);
        const userData = {
          ...omit(
            selectedUser,
            'id',
            'locations',
            'createdAt',
            'updatedAt',
            'deletedAt',
            'projectId',
            'tags'
          ),
          tagIds: tagIds,
        };
        SUserService.updateUserDetails(userData, selectedUser.id)
          .then(() => {
            handleCloseEdit();
            if (selectedLocation?.id) fetchUsersOfLocation(selectedLocation.id);
            showSnackbar('User details updated successfully!', 'success');
          })
          .catch((error) => {
            console.error(error);
            showSnackbar('User details update failed!', 'error');
          });
      } else {
        setEmailError('Please enter a valid email!');
      }
    } else {
      setEmailError('Secondary Email cannot be empty!!!');
    }
  };

  const handleEditUser = (user: IUserDetailsWithTags) => {
    setSelectedUser(user);
    setUserLocation(user?.locations?.[0]);
    setEditUserDialogue(true);
  };

  const resetPage = () => {
    setIsUserAADOnly(false);
    setSelectedUser(undefined);
    setUserLocation(undefined);
    setSelectedLocation(null);
    setSelectedAADUser(undefined);
    setUsersList([]);
  };

  const handleTabChange = (e: React.SyntheticEvent, tab: number) => {
    setTabValue(tab);
    resetPage();
  };

  const getUserDetailsByAADId = (AADUserId: string) => {
    SUserService.getUserWithLocationsByAadId(AADUserId).then((user) => {
      if (user) {
        setIsUserAADOnly(false);
        handleEditUser(user);
      } else {
        setIsUserAADOnly(true);
      }
    });
  };

  const handleAADUserSelect = (
    e: SyntheticEvent<Element, Event>,
    AADUser: User | null
  ) => {
    if (AADUser?.id) {
      setSelectedAADUser(AADUser);
      getUserDetailsByAADId(AADUser.id);
    }
  };

  const handleDBUserSelect = (
    e: SyntheticEvent<Element, Event>,
    DBUserEmail: string | null
  ) => {
    if (DBUserEmail) {
      setSearchQuery(DBUserEmail);
    }
  };

  const handleCreateUserInDB = async () => {
    if (selectedAADUser?.id) {
      const userData = {
        emailAddress: selectedAADUser.userPrincipalName ?? undefined, //get email from AAD,
        isAdmin: false,
        isLocked: false,
        isSuperUser: false,
        aadObjectId: selectedAADUser.id,
      };
      await SUserService.addUserDetails(userData);
      getUserDetailsByAADId(selectedAADUser.id);
    }
  };

  const debouncedRefetchAADUsers = useCallback(
    debounce(() => refetchAadUsers(), 250), // optimal debounce Time for a good UX
    [refetchAadUsers]
  );

  const debouncedRefetchDBUsers = useCallback(
    debounce(() => refetchDbUsers(), 250),
    [refetchDbUsers]
  );

  useEffect(() => {
    if (aadUsersError) alert(aadUsersError);
  }, [aadUsersError]);

  useEffect(() => {
    if (dbUsersError) alert(dbUsersError);
  }, [dbUsersError]);

  useEffect(() => {
    if ([UserTab.FROM_AAD, UserTab.FROM_DB].includes(tabValue)) {
      if (tabValue === UserTab.FROM_AAD) {
        debouncedRefetchAADUsers();
      } else if (tabValue === UserTab.FROM_DB) {
        debouncedRefetchDBUsers();
      }
    }
  }, [
    searchQuery,
    debouncedRefetchAADUsers,
    debouncedRefetchDBUsers,
    tabValue,
  ]);

  useEffect(() => {
    if (aadUsersData) {
      setAADUsers(aadUsersData);
    }
  }, [aadUsersData]);

  useEffect(() => {
    if (dbUsersData) {
      setUsersList(dbUsersData);
    }
  }, [dbUsersData]);

  useEffect(() => {
    setSearchQuery('');
  }, [tabValue]);

  return (
    <div className="user-main-container">
      <div className="user-overview-header-container">
        <Typography variant="h4">Users</Typography>
        <Button
          variant="outlined"
          onClick={() => setNewUserDialogue(true)}
          endIcon={<PersonAddIcon />}
        >
          Create User
        </Button>
      </div>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab icon={<FactoryIcon />} label="BY PROJECTS" />
        <Tab icon={<PersonPinIcon />} label="FROM AAD" />
        <Tab icon={<PersonPinIcon />} label="FROM DB" />
      </Tabs>
      {tabValue === UserTab.BY_PROJECT && (
        <>
          <div className="location-select-user">
            <FormControl required sx={{ mb: 5 }}>
              {locationList?.length ? (
                <LocationAutocomplete
                  locationList={locationList}
                  onSelectedLocationChange={handleLocationSelect}
                  value={selectedLocation}
                />
              ) : (
                <CircularProgress size="30px" />
              )}
            </FormControl>
          </div>
          {selectedLocation && (
            <ListUsers
              loading={usersLoading}
              usersList={usersList}
              onUserEdit={handleEditUser}
              refreshUsersList={() => {
                fetchUsersOfLocation(selectedLocation.id);
              }}
            />
          )}
        </>
      )}
      {tabValue === UserTab.FROM_AAD && (
        <>
          <div className="aad-users-container">
            <Autocomplete
              id="aad-user-autocomplete"
              options={AADUsers}
              loading={isAadUsersFetching}
              getOptionLabel={(user) => user.userPrincipalName || ''}
              onChange={handleAADUserSelect}
              value={selectedAADUser || null}
              inputValue={searchQuery}
              onInputChange={(event, newInputValue) => {
                setSearchQuery(newInputValue);
              }}
              selectOnFocus
              isOptionEqualToValue={(option, value) =>
                option.userPrincipalName === value.userPrincipalName
              }
              renderInput={(params) => (
                <TextField {...params} label="Select User" />
              )}
            />
          </div>
          {isUserAADOnly && (
            <>
              <div className="no-location-msg">
                The selected user has no entry in the database.
              </div>
              <div>
                <div className="add-location-btn">
                  <Button
                    sx={{
                      backgroundColor: '#234C8A',
                      color: '#ffff',
                      fontFamily: 'Inria Sans',
                      '&:hover': {
                        backgroundColor: '#ffff',
                        color: '#234c8a',
                      },
                    }}
                    variant="outlined"
                    onClick={handleCreateUserInDB}
                  >
                    Add User
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {tabValue === UserTab.FROM_DB && (
        <>
          <div className="location-select-user">
            <FormControl required sx={{ mb: 5 }}>
              <Autocomplete
                id="db-user-autocomplete"
                options={usersList ? usersList.map((u) => u.emailAddress) : []}
                loading={isDbUsersFetching}
                onChange={handleDBUserSelect}
                value={searchQuery}
                inputValue={searchQuery}
                onInputChange={(event, newInputValue) => {
                  setSearchQuery(newInputValue);
                }}
                selectOnFocus
                renderInput={(params) => (
                  <TextField {...params} label="Search user in DB" />
                )}
              />
            </FormControl>
          </div>
          <ListUsers
            loading={isDbUsersFetching}
            usersList={usersList}
            onUserEdit={handleEditUser}
            refreshUsersList={debouncedRefetchDBUsers}
          />
        </>
      )}
      <NewUserDialog
        open={newUserDialogue}
        handleClose={() => setNewUserDialogue(false)}
        refreshUsersList={() => {
          if (selectedLocation) {
            fetchUsersOfLocation(selectedLocation?.id);
          }
        }}
      />
      {selectedUser && (
        <EditUserDetails
          editUserDialogue={editUserDialogue}
          handleCloseEdit={handleCloseEdit}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          emailError={emailError}
          setEmailError={setEmailError}
          locationsList={locationList}
          userLocation={userLocation ?? undefined}
          setUserLocation={setUserLocation}
          handleUpdateUserDetails={handleUpdateUserDetails}
        />
      )}
    </div>
  );
};

export default Users;
