import React, { useEffect, useMemo, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ITag } from 'interfaces/tag.interface';
import { debounce } from 'lodash';
import { LoadingComponent } from '../../components/loading/Loading.component';
import SearchBar from '../../components/SearchBar/SearchBar.component';
import { TagContainer } from 'pages/Tags/style';
import { TableWrapper } from 'pages/Reports/style';
import SUserService from 'services/user/user.service';
import { TagComponent } from 'components/Tag/tag.component';
import EditIcon from '@mui/icons-material/Edit';
import './UsersTags.Component.scss';
import AddTags from './AddTags/AddTags.Component';
import { IUserDetailsWithTags } from 'interfaces/user.interface';

const UsersTags: React.FunctionComponent = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsersList, setFilteredUsersList] = useState<
    IUserDetailsWithTags[]
  >([]);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const { data: usersList, isFetching: loading } =
    SUserService.useAllUserNamesWithTags();

  // Memoize the debounced function to prevent it from being recreated on every render
  const debouncedFilterUsers = useMemo(() => {
    return debounce((query, list) => {
      const lowerCaseQuery = query?.toLowerCase();
      const filteredUsers = list?.filter((user: IUserDetailsWithTags) => {
        return user.emailAddress?.toLowerCase().includes(lowerCaseQuery);
      });
      setFilteredUsersList(filteredUsers);
    }, 250);
  }, []);

  useEffect(() => {
    debouncedFilterUsers(searchQuery, usersList);

    // Cleanup function to cancel the debounced function call if searchQuery or tagsList changes
    return () => {
      debouncedFilterUsers.cancel();
    };
  }, [searchQuery, usersList, debouncedFilterUsers]);

  const selectedUser = useMemo(() => {
    return usersList?.find((user) => user.id === selectedUserId);
  }, [usersList, selectedUserId]);

  return (
    <TagContainer>
      {loading ? (
        <div style={{ marginBottom: '20px' }}>
          <LoadingComponent />
        </div>
      ) : (
        <TableWrapper className="table-wrapper">
          <SearchBar
            searchLabel="Search Users"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow selected>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Tags</TableCell>
                  <TableCell align="left">Edit</TableCell>
                </TableRow>
              </TableHead>
              {filteredUsersList?.length > 0 && (
                <TableBody>
                  {filteredUsersList?.map((user: IUserDetailsWithTags) => (
                    <TableRow
                      key={user.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{ maxWidth: 300, wordWrap: 'break-word' }}
                        align="left"
                      >
                        {user.emailAddress}
                      </TableCell>
                      <TableCell align="left">
                        <div className="tags-container">
                          {user.tags.map((tag: ITag) => {
                            return (
                              <TagComponent
                                key={tag.id}
                                tag={tag}
                              ></TagComponent>
                            );
                          })}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          onClick={() => {
                            setSelectedUserId(user.id);
                            setIsEditOpen(true);
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {selectedUser && isEditOpen && (
            <AddTags
              open={isEditOpen}
              setOpen={setIsEditOpen}
              selectedUser={selectedUser}
              usersList={usersList}
            />
          )}
        </TableWrapper>
      )}
    </TagContainer>
  );
};

export default UsersTags;
