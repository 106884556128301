import LocationAutocomplete from 'components/LocationAutocomplete/LocationAutocomplete';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import './EditUserDetails.scss';
import { mapUserType, UserTypes } from 'constants/user';
import React, {
  Dispatch,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FunctionComponent } from 'react';
import {
  ILocationDetails,
  ILocationMiniInfo,
} from 'interfaces/location.interface';
import {
  IUpdateUserDetailsWithTags,
  IUserDetailsWithTags,
} from 'interfaces/user.interface';
import SLocationService from 'services/location/location.service';
import SUserService from 'services/user/user.service';
import { LocationMultiSelect } from 'components/LocationMultiSelect/LocationMultiSelect.component';
import { Info } from '@mui/icons-material';
import { TagFilter } from 'components/tagFilter/TagFilter.component';
import { TagComponent } from 'components/Tag/tag.component';
import { ITag } from 'interfaces/tag.interface';

interface IEditUserDetails {
  editUserDialogue: boolean;
  handleCloseEdit: () => void;
  selectedUser: IUpdateUserDetailsWithTags;
  setSelectedUser: Dispatch<
    React.SetStateAction<IUpdateUserDetailsWithTags | undefined>
  >;
  emailError: string;
  setEmailError: Dispatch<React.SetStateAction<string>>;
  locationsList?: ILocationMiniInfo[];
  userLocation: ILocationMiniInfo | undefined;
  setUserLocation: Dispatch<
    React.SetStateAction<ILocationMiniInfo | undefined | null>
  >;
  handleUpdateUserDetails: () => void;
}

interface InputType {
  name: string;
  label: string;
}

export const EditUserDetails: FunctionComponent<IEditUserDetails> = ({
  editUserDialogue,
  handleCloseEdit,
  selectedUser,
  setSelectedUser,
  emailError,
  setEmailError,
  handleUpdateUserDetails,
}) => {
  const checkBoxes: InputType[] = [
    { name: 'isAdmin', label: 'Admin' },
    { name: 'isLocked', label: 'Locked' },
    { name: 'isSuperUser', label: 'Super User' },
  ];
  const [locationSet, setLocationSet] = useState<ILocationMiniInfo[] | null>(
    null
  );
  const [selectedLocations, setSelectedLocations] = useState<
    ILocationMiniInfo[]
  >([]);
  const userTypeRef = useRef<UserTypes | undefined>(selectedUser.userType);

  useEffect(() => {
    return () => {
      userTypeRef.current = undefined;
    };
  }, []);

  useEffect(() => {
    userTypeRef.current = selectedUser.userType;
  }, [selectedUser.id]);

  useEffect(() => {
    if (selectedUser.userType) {
      SLocationService.findNames(selectedUser?.userType).then((response) => {
        if (response && response.length > 0) {
          setLocationSet(response);
        }
      });
    }
  }, [selectedUser.userType]);

  useEffect(() => {
    if (selectedUser?.aadObjectId) {
      SUserService.getUserWithLocationsByAadId(selectedUser.aadObjectId).then(
        (response) => {
          const locations = response?.locations ?? [];
          if (locations.length > 0) {
            const locListMini = locations.map((location) => ({
              id: location.id,
              name: location.name,
              projectId: location.projectId,
            }));
            setSelectedLocations(locListMini);
          }
        }
      );
    }
  }, [selectedUser?.aadObjectId]);

  const handleCancel = () => {
    setEmailError('');
    setSelectedLocations([]);
    setSelectedUser(undefined);
    handleCloseEdit();
  };

  const handleCheckBoxValueChange = <Key extends keyof IUserDetailsWithTags>(
    property: Key
  ) => {
    setSelectedUser({
      ...selectedUser,
      [property]: !selectedUser[property],
    });
  };

  const handleSelectedUserLocationChange = (
    e: SyntheticEvent<Element, Event>,
    location: ILocationMiniInfo | null
  ) => {
    setSelectedLocations([location as ILocationDetails]);
    if (location)
      setSelectedUser({ ...selectedUser, locationIds: [location.id] });
  };

  const handleProjectUserLocationsChange = (value: ILocationMiniInfo[]) => {
    setSelectedLocations(value);
    const locationIds = value.map((location: ILocationMiniInfo) => location.id);
    if (location)
      setSelectedUser({ ...selectedUser, locationIds: locationIds });
  };

  const deleteTags = (id: number) => {
    const updatedTags = selectedUser.tags.filter((tag) => tag.id !== id);
    setSelectedUser({ ...selectedUser, tags: updatedTags });
  };

  return (
    <Dialog
      open={editUserDialogue}
      onClose={handleCloseEdit}
      className="user-edit-form"
    >
      <DialogTitle>{'Edit User Details'}</DialogTitle>
      <DialogContent>
        <FormGroup className="checkbox-container">
          {checkBoxes.map((checkBox, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={Boolean(
                      selectedUser[checkBox.name as keyof IUserDetailsWithTags]
                    )}
                    onChange={() => {
                      handleCheckBoxValueChange(
                        checkBox.name as keyof IUserDetailsWithTags
                      );
                    }}
                  />
                }
                label={checkBox.label}
              />
            );
          })}
        </FormGroup>
        <TextField
          label="Secondary Email"
          required
          sx={{ width: '100%' }}
          error={emailError !== '' ? true : false}
          helperText={emailError || ''}
          type="text"
          value={selectedUser.secondaryEmail || ''}
          onChange={(event: { target: { value: string } }) => {
            setEmailError('');
            setSelectedUser({
              ...selectedUser,
              secondaryEmail: event.target.value,
            });
          }}
        />
        <div className="user-type-select">
          <FormControl>
            <InputLabel id="user-type-select-label">
              {'Select User Type'}
            </InputLabel>
            <Select
              required
              id="user-type-select"
              value={String(selectedUser.userType)}
              disabled={!!userTypeRef.current}
              label={'Select User Type'}
              onChange={(event: SelectChangeEvent) =>
                setSelectedUser({
                  ...selectedUser,
                  userType: Number(event.target.value) as unknown as UserTypes,
                })
              }
            >
              {mapUserType?.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {selectedUser?.userType != UserTypes.ProjectUser && (
          <FormControl required sx={{ mb: 5, width: '100%' }}>
            <LocationAutocomplete
              locationList={locationSet || []}
              sx={{ width: '100%' }}
              onSelectedLocationChange={handleSelectedUserLocationChange}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              value={selectedLocations[0] || null}
            />
          </FormControl>
        )}

        {selectedUser?.userType == UserTypes.ProjectUser && (
          <LocationMultiSelect
            selectedLocations={selectedLocations}
            onChange={handleProjectUserLocationsChange}
            disabled={!selectedUser}
            locationSet={locationSet}
          />
        )}

        <div className="tags-container">
          <div className="tags-title">
            <Typography variant="body1">Add Tags</Typography>
            {/* TODO: check if backend method is already copying provider tags from the linked location to the user */}
            <Tooltip title="Please add corresponding Provider tag">
              <Info />
            </Tooltip>
          </div>
          <TagFilter
            tags={selectedUser.tags}
            onChange={(tags: ITag[]) => {
              setSelectedUser({ ...selectedUser, tags: tags });
            }}
          />
          <div className="tags-container-pop-up">
            {selectedUser.tags.map((tag: ITag) => {
              return (
                <TagComponent
                  key={tag.id}
                  deleteEnabled={true}
                  deleteAction={deleteTags}
                  tag={tag}
                />
              );
            })}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="button-wrapper">
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" onClick={handleUpdateUserDetails}>
            Update User
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
