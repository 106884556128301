import { IReportDetails } from 'interfaces/reports.interface';

export const reportInputValidator = (
  reportDetails: Partial<IReportDetails>
) => {
  const reportError: Record<string, string> = {};

  if (!reportDetails?.reportId?.trim()) {
    reportError.reportId = 'ReportId is mandatory!';
  }

  if (!reportDetails?.workspaceId?.trim()) {
    reportError.workspaceId = 'WorkspaceId is mandatory!';
  }

  if (!reportDetails?.datasetId?.trim()) {
    reportError.datasetId = 'DatasetId is mandatory!';
  }

  if (!reportDetails?.reportName?.trim()) {
    reportError.reportName = 'Report name is mandatory and cannot be empty!';
  }

  return reportError;
};
