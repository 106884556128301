import { models } from 'powerbi-client';

export enum PowerBIReportTypes {
  CUSTOM = 'CUSTOM',
  SUMMARY = 'SUMMARY',
  ANALYTICS = 'ANALYTICS',
}

export enum ReportStatus {
  ACTIVE = 'ACTIVE',
  HIDE = 'HIDE',
}

export const defaultReportError = {
  reportId: '',
  datasetId: '',
  workspaceId: '',
  reportName: '',
};

export const defaultReportDetails = {
  powerBIReportTypes: PowerBIReportTypes.SUMMARY,
  reportStatus: ReportStatus.ACTIVE,
  reportId: '',
  datasetId: '',
  workspaceId: '',
  reportName: '',
  roleName: '',
};

export const getPowerBIEmbedConfig = (
  dashboardId: string | undefined,
  accessToken: string | undefined,
  embedUrl: string | undefined
) => {
  return {
    type: 'report',
    id: dashboardId,
    embedUrl: embedUrl,
    accessToken: accessToken,
    tokenType: models.TokenType.Embed,
    settings: {
      layoutType: models.LayoutType.Custom,
      customLayout: {
        displayOption: models.DisplayOption.FitToPage,
      },
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
      },
      navContentPaneEnabled: false,
      background: models.BackgroundType.Default,
    },
  };
};
